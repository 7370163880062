<script setup lang="ts">
import QrScanner from 'qr-scanner'

const emit = defineEmits(['read'])

let scanner: QrScanner = {} as QrScanner

const router = useRouter()

function setResult(result: QrScanner.ScanResult) {
  scanner.stop() // Avoids new readings
  emit('read', result.data)
  router.push(`/vehicles/${parseInt(result.data.split(':')[1])}`).then(() => {
    location.reload()
  })
}

onMounted(() => {
  const video: any = document.getElementById('qr-video')

  scanner = new QrScanner(video, (result) => setResult(result), {
    onDecodeError: () => {},
    highlightScanRegion: true,
    highlightCodeOutline: true,
    maxScansPerSecond: 10,
    preferredCamera: 'environment',
  })

  scanner.start()
})

onUnmounted(() => {
  scanner.stop()
  scanner.destroy()
})

const flashLightStatus = ref(false)

function toogleFlashlight() {
  if (flashLightStatus.value) {
    scanner.turnFlashOff()
    flashLightStatus.value = false
  } else {
    scanner.turnFlashOn()
    flashLightStatus.value = true
  }
}
</script>

<template>
  <div>
    <video id="qr-video"></video>
    <div class="mt-4 flex justify-center">
      <HButton
        icon="icon-park-outline:flashlight"
        text="Toogle light"
        size="sm"
        rounded
        class="border-2 bg-river"
        @click="toogleFlashlight"
      ></HButton>
    </div>
  </div>
</template>

<style>
#qr-video {
  height: 384px;
  width: 100%;
  @apply object-cover;
}

.scan-region-highlight-svg,
.code-outline-highlight {
  @apply stroke-leaf !important;
}
</style>
